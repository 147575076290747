<div class="cta">
  <div class="cta-content">
    <div class="cta-button">
      <span>free chat</span>
    </div>
    <div class="icons">
      <div class="toy icon">
        <div class="icon-img">
          <img class="toy" src="./assets/img/toy.svg" alt="toy" />
        </div>
        <span>interactive toys</span>
      </div>
      <div class="coin icon">
        <div class="icon-img">
          <img class="coin" src="./assets/img/coin.svg" alt="coin" />
        </div>
        <span>100<sup>tks</sup> for <sup>$</sup>1</span>
      </div>
      <div class="chat icon">
        <div class="icon-img">
          <img class="private" src="./assets/img/private.svg" alt="private chat" />
        </div>
        <span>private chat</span>
      </div>
    </div>
  </div>
</div>
