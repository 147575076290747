import { createApp } from 'vue';
import { createRouter, createWebHistory } from 'vue-router';
import App from './App.vue';
import NotFound from './pages/NotFound/NotFound.vue';
import Ad1 from './pages/Ad1/Ad1.vue';
import Ad2 from './pages/Ad2/Ad2.vue';
import Ad3 from './pages/Ad3/Ad3.vue';

const routes = [
    { path: '/ad1', name: 'Ad1', component: Ad1 },
    { path: '/ad2', name: 'Ad2', component: Ad2 },
    { path: '/ad3', name: 'Ad3', component: Ad3 },
    { path: '/:pathMatch(.*)*', name: 'NotFound', component: NotFound },
];

const router = createRouter({
    history: createWebHistory('/'),
    routes,
});

const app = createApp(App);
app.use(router);
app.mount('#app')
