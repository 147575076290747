import Player from '../../components/Player/Player.vue';
import Cta from '../../components/Cta/Cta.vue';
import LiveIcon from '../../components/LiveIcon/LiveIcon.vue';
import { isMobile } from '@mv-landers/common/lib/utils/devices';

export default {
  name: 'Ad3',
  components: {
    Player,
    Cta,
    LiveIcon,
  },
  data() {
    return {
      video: isMobile() ? '/assets/videos/Codi-Vore-Mobile.mp4' : '/assets/videos/Natasha-Nice-Desktop.mp4',
    }
  },
  methods: {
    isMobile,
    clickAd: () => {
      window.open(`https://www.manyvids.com/offers${window.location.search}`, '_blank');
    }
  }
};
